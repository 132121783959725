<template>
  <div></div>
</template>

<script>
export default {
	name: 'Ranking',
};
</script>

<style>

</style>
